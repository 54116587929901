import ErrorPage from "@/components/pages/ErrorPage";
import { countDashboardDiariesQueryOptions } from "@/features/dashboard/api/useCountDashboardDiaries";
import { dashboardDiaryQueryOptions } from "@/features/dashboard/api/useSelectDashboardDiaries";
import { selectDashboardSettingsByIdQueryOptions } from "@/features/dashboard/api/useSelectDashboardSettings";
import DashboardPage from "@/features/dashboard/components/DashboardPage";
import { hooks as h } from "@/hooks";
import { useParams } from "@tanstack/react-router";
import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

const DashboardPageWrapper = () => {
	const { diaryId } = useParams({ strict: false }) as { diaryId: string };
	h.useNav([{ label: "Dashboard", route: { to: "/dashboard" } }]);
	const { diariesCount, settings, diaries } = Route.useLoaderData();

	return (
		<DashboardPage
			diaryId={diaryId}
			diariesCount={diariesCount}
			settings={settings}
			diaries={diaries}
		/>
	);
};

export const StyleZ = z.enum(["table", "stack"]).catch("table");
export const TabZ = z.enum(["active", "pending", "inactive"]).catch("active");

export type StyleT = z.infer<typeof StyleZ>;
export type TabT = z.infer<typeof TabZ>;

export const SortingZ = z.array(
	z.object({
		desc: z.boolean(),
		id: z.string(),
	}),
);

const defaultPageSize = 10;

export const PaginationZ = z.object({
	pageSize: z.number().default(defaultPageSize),
	pageIndex: z.number().default(0),
});

export type PaginationT = z.infer<typeof PaginationZ>;

export const dashboardSchemaZ = z.object({
	columns: z.string().catch("").optional(),
	style: StyleZ.optional(),
	tab: TabZ.catch("active"),
	diaries: z.enum(["mine", "all"]).catch("mine").optional(),
	organisations: z.array(z.string()).catch(["all"]).optional(),
	diary_access: z.array(z.string().uuid()).optional(),
	pagination: PaginationZ.optional(),
	sorting: SortingZ.optional(),
});

export type DashboardSearchT = z.infer<typeof dashboardSchemaZ>;

export const Route = createFileRoute("/_app/dashboard")({
	component: DashboardPageWrapper,
	errorComponent: ({ error, reset }) => (
		<ErrorPage title="Dashboard Error" error={error as Error} reset={reset} />
	),
	validateSearch: (search) => {
		// Provide a default value for 'tab' if it is not present
		const defaultSearch = {
			tab: "active",
			pagination: { pageIndex: 0, pageSize: defaultPageSize },
			...search,
		};
		return dashboardSchemaZ.parse(defaultSearch);
	},
	loaderDeps: ({ search }) => ({ search }),
	loader: async ({ context, deps }) => {
		const dashboardSettings = await context?.queryClient?.ensureQueryData(
			selectDashboardSettingsByIdQueryOptions(context.auth.id),
		);

		const settings = {
			...(dashboardSettings?.dashboard_settings as DashboardSearchT),
			...deps.search,
		};

		await context?.queryClient?.ensureQueryData(
			dashboardDiaryQueryOptions(settings, context.auth),
		);

		const diariesCount = await context?.queryClient?.ensureQueryData(
			countDashboardDiariesQueryOptions(settings, context.auth),
		);

		const diaries = await context?.queryClient?.ensureQueryData(
			dashboardDiaryQueryOptions(settings, context.auth),
		);
		return { settings: settings, diariesCount: diariesCount, diaries: diaries };
	},
});
