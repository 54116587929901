import { Link } from "@tanstack/react-router";
import { useState } from "react";
import { useFormContext } from "react-hook-form";

import { passwordRequirements } from "@/features/auth/types/signupSchema";
import { ui } from "../ui";
interface Props {
	name?: string;
	label?: string;
	showRequirements: boolean;
	showPassword: boolean;
	tabIndex?: number;
	showForgotPassword?: boolean;
}
const Password: React.FC<Props> = ({
	name = "password",
	label = "Password",
	showRequirements = false,
	showPassword = false,
	tabIndex,
	showForgotPassword,
}) => {
	const { control } = useFormContext();

	const [checked, setChecked] = useState<boolean>(false);

	return (
		<div className="flex flex-col gap-y-2">
			<ui.FormField
				control={control}
				name={name}
				data-cy={name}
				render={({ field }) => (
					<ui.FormItem>
						<div>
							<div className="flex justify-between">
								<ui.FormLabel>{label}</ui.FormLabel>
								{showForgotPassword !== false && (
									<ui.Button
										asChild
										type="button"
										className="p-0 h-fit"
										size="sm"
										variant="link"
									>
										<Link tabIndex={-1} to="/forgot-password">
											Forgot Password
										</Link>
									</ui.Button>
								)}
							</div>
							{showRequirements && (
								<ui.FormDescription>{passwordRequirements}</ui.FormDescription>
							)}
						</div>
						<ui.FormMessage />
						<ui.FormControl>
							<ui.Input
								data-testid="password-input"
								autoComplete="current-password"
								tabIndex={tabIndex ?? 2}
								type={checked ? "text" : "password"}
								{...field}
							/>
						</ui.FormControl>
					</ui.FormItem>
				)}
			/>
			{showPassword && (
				<div className="flex justify-center items-center gap-x-3 mt-2 w-fit">
					<ui.Checkbox
						tabIndex={-1}
						checked={checked as boolean}
						onClick={() => setChecked(!checked)}
					/>
					<ui.Label className="text-sm">Show Password</ui.Label>
				</div>
			)}
		</div>
	);
};

export default Password;
