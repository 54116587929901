import { useCountDashboardDiariesQuery } from "@/features/dashboard/api/useCountDashboardDiaries";
import { useDashboardDiariesQuery } from "@/features/dashboard/api/useSelectDashboardDiaries";
import type { DashboardSearchT } from "@/routes/_app/dashboard";
import { useSearch } from "@tanstack/react-router";

export function useRefetchDashboardData() {
	const search = useSearch({ strict: false }) as DashboardSearchT;
	const { refetch: refetchDiaries } = useDashboardDiariesQuery(search);
	const { refetch: refetchCount } = useCountDashboardDiariesQuery(search);

	const refetchDashboardData = async () => {
		console.log("refetching dashboard data");
		await Promise.all([refetchDiaries(), refetchCount()]);
	};

	return refetchDashboardData;
}
