import { commonComponents as cc } from "@/components/common";
import { useAuth } from "@/utils/auth";

import type { StepT } from "@/components/common/Stepper";

// Define route paths as constants
export const routes = {
	organisation: "/onboarding/organisation",
	name: "/onboarding/name",
	dateOfBirth: "/onboarding/date-of-birth",
	phone: "/onboarding/phone",
	notifications: "/onboarding/notifications",
	complete: "/onboarding/complete",
	clinicianNumber: "/onboarding/clinician-number",
	favouriteDiaryTemplates: "/onboarding/favourite-diary-templates",
	profile: "/profile",
} as const;

export const patientSteps: Omit<StepT, "stage">[] = [
	{ words: "Organisation", route: { to: routes.organisation } },
	{ words: "Name", route: { to: routes.name } },
	{ words: "Date of Birth", route: { to: routes.dateOfBirth } },
	{ words: "Phone", route: { to: routes.phone } },
	{ words: "Notifications", route: { to: routes.notifications } },
	{ words: "Complete", route: { to: routes.complete } },
];

export const clinicianSteps: Omit<StepT, "stage">[] = [
	{ words: "Organisation", route: { to: routes.organisation } },
	{ words: "Name", route: { to: routes.name } },
	{ words: "Clinician Number", route: { to: routes.clinicianNumber } },
	{ words: "Notifications", route: { to: routes.notifications } },
	{
		words: "Favourite Diary Templates",
		route: { to: routes.favouriteDiaryTemplates },
	},
];

export const secretarySteps: Omit<StepT, "stage">[] = [
	{ words: "Organisation", route: { to: routes.organisation } },
	{ words: "Name", route: { to: routes.name } },
	{ words: "Notifications", route: { to: routes.notifications } },
	{
		words: "Favourite Diary Templates",
		route: { to: routes.favouriteDiaryTemplates },
	},
];

const OnboardingStepper = ({ currentRoute }: { currentRoute: string }) => {
	const auth = useAuth();

	const steps = (() => {
		if (auth.roles?.isClinician) return clinicianSteps;
		if (auth.roles?.isSecretary) return secretarySteps;
		return patientSteps; // default case
	})();

	// Update the stages based on the current route
	const updatedSteps: StepT[] = steps.map((step) => ({
		...step,
		stage:
			step.route?.to === currentRoute
				? "current"
				: steps.findIndex((s) => s.route?.to === currentRoute) >
						steps.findIndex((s) => s.route?.to === step.route?.to)
					? "past"
					: "future",
	}));

	return <cc.Stepper steps={updatedSteps} />;
};

export default OnboardingStepper;
