import { ui } from "../../../components/ui";
import { buttonVariants } from "../../../components/ui/button";
import { cn } from "../../../lib/utils";
import ImpersonateUser from "./ImpersonateUser";

const ImpersonateUserDialog = () => {
	return (
		<ui.Dialog>
			<ui.DialogTrigger className={cn(buttonVariants({ size: "md" }), "")}>
				Impersonate User
			</ui.DialogTrigger>
			<ui.DialogContent>
				<ui.DialogHeader>
					<ui.DialogTitle className="text-slate-900 dark:text-slate-100">
						Impersonate
					</ui.DialogTitle>
				</ui.DialogHeader>
				<ui.ScrollArea className="md:p-2 lg:p-4 max-h-[80vh]">
					<ImpersonateUser />
				</ui.ScrollArea>
			</ui.DialogContent>
		</ui.Dialog>
	);
};

export default ImpersonateUserDialog;
