import type { ExtendedProfileT } from '@/types/database/profiles';
import { useSuspenseQuery } from '@tanstack/react-query';

import { useAuth } from '../../../utils/auth';
import { selectProfileByIdQueryOptions } from '../api/selectProfile';

export interface ProfileAttributesT {
	[key: string]: boolean;
}

const noName = (profile) => !profile?.title || !profile?.first_name || !profile?.last_name;

// Profile Incomplete Attributes are counted, if True then Incomplete
const patientProfile = (profile: ExtendedProfileT): ProfileAttributesT => {
	return {
		organisation: profile?.organisations?.length === 0,
		name: noName(profile),
		'date-of-birth': profile?.date_of_birth === null,
		email: profile?.email === null,
		phone: profile?.phone === null,
	};
};

const clinicianProfile = (profile: ExtendedProfileT): ProfileAttributesT => {
	return {
		organisation: profile?.organisations?.length === 0,
		name: noName(profile),
		email: profile.email === null,
	};
};

const secretaryProfile = (profile: ExtendedProfileT): ProfileAttributesT => {
	return {
		organisation: profile?.organisations?.length === 0,
		name: noName(profile),
		email: profile.email === null,
	};
};

const useProfileAttributes = () => {
	const auth = useAuth();
	const { data: profile } = useSuspenseQuery(selectProfileByIdQueryOptions(auth.id));

	if (!profile) {
		return {};
	}

	if (auth.roles?.isPatient && !!profile) {
		return patientProfile(profile);
	}
	if (auth.roles?.isClinician && !!profile) {
		return clinicianProfile(profile);
	}
	if (auth.roles?.isSecretary && !!profile) {
		return secretaryProfile(profile);
	}
	return { organisation: true } as ProfileAttributesT;
};

export default useProfileAttributes;
