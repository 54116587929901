import { formComponents as fc } from '@/components/form';
import { Form } from '@/components/ui/form';
import { useForm } from '@/utils/form';
import { Button } from '../../../components/ui/button';
import { usePreferencesMutation } from '../api/preferencesMutation';
import { usePreferencesQuery } from '../api/preferencesQuery';
import { DialogClose } from '../../../components/ui/dialog';
import NotificationsDisabledAlert from './NotificationsDisabledAlert';

interface Props {
	onSuccess?: () => void;
	isDialog?: boolean;
}

export default function PreferencesForm({ onSuccess, isDialog = true }: Props) {
	const { data: preferences } = usePreferencesQuery();
	const updatePreferences = usePreferencesMutation();

	const form = useForm({
		defaultValues: {
			email: preferences?.channel_types?.email ?? false,
			sms: preferences?.channel_types?.sms ?? false,
		},
	});


	const onSubmit = async (data) => {
		await updatePreferences.mutate(data, { onSuccess });
	};

	const email = form.watch('email')
	const sms = form.watch('sms')

	return (
		<Form {...form}>
			<NotificationsDisabledAlert isVisible={!email && !sms} />
			<form className="form" onSubmit={form.handleSubmit(onSubmit)}>
				<div className="space-y-4 border-slate-200 bg-white p-4 border rounded-lg">
					<fc.Switch name="email" label="Email" />
					<fc.Switch name="sms" label="SMS" />
				</div>
				{isDialog ? (
					<DialogClose asChild>
						<Button type="submit">Save</Button>
					</DialogClose>
				) : (
					<Button type="submit">Save</Button>
				)}
			</form>
		</Form>
	);
}
