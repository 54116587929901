import EditDialog from "@/components/common/EditDialog";
import NameForm from "@/features/profile/components/NameForm";
import { services as s } from "@/services";
import type { ProfileT } from "@/types/database/profiles";
import { Copy } from "../../../components/common/Copy";
import type { TitleT } from "../../../types/zod/auth";
import ClinicianNumberForm from "./ClinicianNumberForm";
import DateOfBirthForm from "./DateOfBirthForm";
import EmailForm from "./EmailForm";
import NhsNumberForm from "./NhsNumberForm";
import PhoneForm from "./PhoneForm";

interface Props {
	profile: ProfileT;
	locked?: boolean;
	show?: boolean;
	editable?: boolean;
}
export const NameDialog = ({
	profile,
	locked = false,
	show = true,
	editable = true,
}: Props) => {
	return (
		<EditDialog
			noInfo={!profile?.title || !profile?.first_name || !profile?.last_name}
			editable={editable}
			locked={locked}
			label="Name"
			info={
				<Copy id={`copy-${profile.name}`} value={profile.name ?? "No Name"}>
					{profile.name ?? "No Name"}
				</Copy>
			}
			show={show}
		>
			<NameForm
				userId={profile.user_id}
				firstName={profile.first_name}
				title={profile.title as TitleT}
				lastName={profile.last_name}
			/>
		</EditDialog>
	);
};

export const DateOfBirthDialog = ({
	profile,
	locked = false,
	show = true,
	editable = true,
}: Props) => {
	return (
		<EditDialog
			editable={editable}
			locked={locked}
			label="Date of Birth"
			noInfo={!profile?.date_of_birth}
			info={
				<Copy
					id={`copy-${profile.date_of_birth}`}
					value={profile.date_of_birth ?? "No Date of Birth"}
				>
					{s.time.formatDate(profile.date_of_birth) ?? "No Date of Birth"}
				</Copy>
			}
			show={show}
		>
			<DateOfBirthForm
				userId={profile.user_id}
				dateOfBirth={profile.date_of_birth}
			/>
		</EditDialog>
	);
};
export const EmailDialog = ({
	profile,
	locked = false,
	show = true,
	editable = true,
}: Props) => {
	return (
		<EditDialog
			editable={editable}
			locked={locked}
			label="Email"
			info={
				<Copy id={`copy-${profile.email}`} value={profile.email ?? "No Email"}>
					{profile.email ?? "No Email"}
				</Copy>
			}
			show={show}
			noInfo={!profile.email}
		>
			<EmailForm userId={profile.user_id} email={profile.email} />
		</EditDialog>
	);
};

export const PhoneDialog = ({
	profile,
	locked = false,
	show = true,
	editable = true,
}: Props) => {
	return (
		<EditDialog
			editable={editable}
			info={
				<Copy
					id={`copy-${profile.phone}`}
					value={profile.phone ?? "No Phone Number"}
				>
					{profile.phone ?? "No Phone Number"}
				</Copy>
			}
			locked={locked}
			label="Phone"
			noInfo={!profile.phone}
			show={show}
		>
			<PhoneForm userId={profile.user_id} phone={profile.phone} />
		</EditDialog>
	);
};

export const NhsNumberDialog = ({
	profile,
	locked = false,
	show = true,
	editable = true,
}: Props) => {
	return (
		<EditDialog
			editable={editable}
			locked={locked}
			label="NHS Number"
			show={show}
			info={
				<Copy
					id={`copy-${profile.nhs_number}`}
					value={String(profile.nhs_number) ?? "No Clinician Number"}
				>
					{profile.phone ?? "No Clinician Number"}
				</Copy>
			}
			noInfo={!profile.nhs_number}
		>
			<NhsNumberForm userId={profile.user_id} nhsNumber={profile.nhs_number} />
		</EditDialog>
	);
};

export const ClinicianNumberDialog = ({
	profile,
	locked = false,
	show = true,
	editable = true,
}: Props) => {
	return (
		<EditDialog
			editable={editable}
			locked={locked}
			label="Clinician Number"
			description="This is your GMC or HCPC number"
			show={show}
			info={
				<Copy
					id={`copy-${profile.clinician_number}`}
					value={String(profile.clinician_number) ?? "No Clinician Number"}
				>
					{profile.clinician_number ?? "No NHS Number"}
				</Copy>
			}
			noInfo={!profile.clinician_number}
		>
			<ClinicianNumberForm
				userId={profile.user_id}
				clinicianNumber={profile.clinician_number}
			/>
		</EditDialog>
	);
};
