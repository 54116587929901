import ErrorPage from '@/components/pages/ErrorPage'
import {
	useSetRoles,
	usersOrganisationsRolesQueryOptions
} from '@/features/auth/api/selectUsersOrganisationsRoles'
import { useKnockAuth } from '../features/knock/api/useKnockAuth'
import Navigation from '@/features/navigation/components/Navigation'
import { selectProfileByIdQueryOptions } from '@/features/profile/api/selectProfile'
import { Outlet, createFileRoute, redirect } from '@tanstack/react-router'
import { NotificationWarning } from '../features/knock/components/NotificationsWarning'

function App() {
	useSetRoles()
	useKnockAuth()
	return (
		<main className='pb-2 min-h-screen'>
			<NotificationWarning />
			<Navigation app={true} />
			<section className=''>
				<Outlet />
			</section>
		</main>
	)
}

export const Route = createFileRoute('/_app')({
	component: App,
	beforeLoad: ({ context }) => {
		if (context?.auth?.isAuthenticated === false) {
			throw redirect({
				to: '/login'
			})
		}

		return context
	},
	loader: async ({ context }) => {
		const roles = await context?.queryClient?.ensureQueryData(
			usersOrganisationsRolesQueryOptions(context?.auth?.id)
		)
		context?.auth?.roles?.setRoles(roles)

		const profile = await context?.queryClient?.ensureQueryData(
			selectProfileByIdQueryOptions(context?.auth?.id)
		)
		context?.auth?.setIsSuperUser(profile?.is_super_user ?? false)
	},
	errorComponent: ({ error, reset }) => (
		<ErrorPage title='App Error' error={error as Error} reset={reset} />
	)
})
