import { RouterSpinner } from "@/components/common/RouterSpinner";
import { Toaster } from "@/components/ui/sonner";
import type { NavT } from "@/utils/nav";
import { Analytics } from "@vercel/analytics/react";

// import type { RolesT } from '@/utils/roles';
import type { QueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Outlet, createRootRouteWithContext } from "@tanstack/react-router";
import React, { Suspense } from "react";
import { isMobile } from "react-device-detect";
import ErrorPage from "../components/pages/ErrorPage";
import NotFoundPage from "../components/pages/NotFoundPage";
import { ui } from "../components/ui";
import useAuthQuery from "../features/auth/api/useAuthQuery";
import { cn } from "../lib/utils";
import type { Auth } from "../utils/auth";

export const Route = createRootRouteWithContext<{
	queryClient: QueryClient;
	auth: Auth;
	dark: boolean;
	nav: NavT;
}>()({
	component: RootComponent,
	errorComponent: ({ error, reset }) => (
		<ErrorPage error={error} reset={reset} />
	),
	notFoundComponent: NotFoundPage,
});

const TanStackRouterDevtools =
	process.env.NODE_ENV === "production"
		? () => null // Render nothing in production
		: React.lazy(() =>
				// Lazy load in development
				import("@tanstack/router-devtools").then((res) => ({
					default: res.TanStackRouterDevtools,
					// For Embedded Mode
					// default: res.TanStackRouterDevtoolsPanel
				})),
			);

function RootComponent() {
	// Using the auth query allows early sign-in. For example if accepting and invite as a new user
	useAuthQuery();
	return (
		<>
			<Analytics />
			<Toaster
				// richColors
				duration={isMobile ? 2500 : 4000}
				expand={true}
				position={isMobile ? "top-center" : "bottom-left"}
				toastOptions={{
					unstyled: true,
					classNames: {
						toast:
							"flex flex-row gap-x-2 p-4 rounded-lg items-center justify-center bg-white dark:bg-slate-800 dark:text-white text-md border-2",
						title: " text-slate-800 dark:text-slate-100",
						description: "text-slate-600 dark:text-slate-300",
						success: "border-indigo-600 text-indigo-600",
						warning: "border-amber-600 text-amber-600",
						error: "border-red-600 text-red-600",
						info: "border-indigo-600 text-indigo-600",
						actionButton:
							"rounded-sm flex bg-indigo-600 text-white p-2 text-xs w-fit text-nowrap items-center justify-center",
						cancelButton: "text-red-500 border-red-500 border-2",
						closeButton: "text-red-500 border-red-500 border-2",
					},
				}}
			/>
			<RouterSpinner />
			<Outlet />
			<div
				className={cn(
					window.location.hostname === "localhost" ? "inline" : "hidden",
				)}
			>
				<ReactQueryDevtools
					initialIsOpen={false}
					buttonPosition="bottom-left"
				/>
				<Suspense>
					<TanStackRouterDevtools position="bottom-right" />
				</Suspense>
			</div>
		</>
	);
}
