import { findStateIcon } from "@/assets/states/StateIcon";
import type { StateDefinitionT } from "@/types/database/diaries";
import Cell from "./TableCell";

interface Props {
	state: StateDefinitionT;
	diaryId: string;
}
const State = ({ state, diaryId }: Props) => {
	return (
		<Cell
			diaryId={diaryId}
			className="flex justify-center items-center gap-x-1 w-fit [&_svg]:w-[35px] [&_svg]:h-[35px] font-medium"
		>
			{findStateIcon(state?.state_key)}
			<p className="w-fit text-nowrap">{state?.name}</p>
		</Cell>
	);
};

export default State;
