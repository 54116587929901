import { X } from 'lucide-react';
import { useState } from 'react';
import { cn } from '../../lib/utils';
interface Props {
	children: React.ReactNode;
	className?: string;
}
const Banner = ({ children, className }: Props) => {
	const [show, setShow] = useState(true);

	return (
		<div
			id="sticky-banner"
			tabIndex={-1}
			className={cn(
				'justify-between w-full p-4 border-b border-slate-200 bg-slate-50 dark:bg-sky-700 dark:border-sky-700',
				show ? 'flex' : 'hidden',
				className,
			)}
		>
			{children}
			<div className="flex items-center">
				<button
					onClick={() => setShow(false)}
					data-dismiss-target="#sticky-banner"
					type="button"
					className="inline-flex flex-shrink-0 justify-center items-center hover:bg-slate-200 dark:hover:bg-slate-600 p-1.5 rounded-lg w-7 h-7 text-slate-600 text-sm hover:text-slate-900 dark:hover:text-white"
				>
					<X size={32} />
					<span className="sr-only">Close banner</span>
				</button>
			</div>
		</div>
	);
};

export default Banner;
