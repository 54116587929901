import { Button } from "@/components/ui/button";
import { useEffect, useState } from "react";
import Banner from "../../../components/common/Banner";
import { useAreAllNotificationsDisabled } from "../api/preferencesQuery";
import PreferencesDialog from "./PreferencesDialog";

export function NotificationWarning() {
	const hasOptedOut = useAreAllNotificationsDisabled();
	const [isVisible, setIsVisible] = useState(hasOptedOut);

	useEffect(() => {
		const isDismissed = localStorage.getItem("notificationWarningDismissed");
		if (hasOptedOut && !isDismissed) {
			setIsVisible(true);
		}
	}, [hasOptedOut]);

	if (!hasOptedOut) return null;

	return (
		<>
			{isVisible && (
				<Banner className="border-amber-200 bg-amber-100 text-amber-800">
					<div className="flex justify-start items-center gap-2 w-full">
						<b className="text-amber-800 text-sm">
							Warning: Notifications Disabled
						</b>
						<PreferencesDialog
							trigger={
								<Button
									variant="outline"
									size="sm"
									className="border-amber-400 bg-amber-100/70 hover:bg-amber-100/50 h-8"
								>
									Edit Preferences
								</Button>
							}
							onSuccess={() => setIsVisible(false)}
						/>
					</div>
				</Banner>
			)}
		</>
	);
}
