import { Button } from '@/components/ui/button';
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from '@/components/ui/dialog';
import PreferencesForm from './PreferencesForm';
interface PreferencesDialogProps {
    trigger?: React.ReactNode;
    onSuccess?: () => void;
}

export default function PreferencesDialog({ trigger, onSuccess }: PreferencesDialogProps) {

    return (
        <Dialog >
            <DialogTrigger asChild>
                {trigger || <Button variant="outline">Notification Preferences</Button>}
            </DialogTrigger>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Notification Preferences</DialogTitle>
                    <DialogDescription>
                        Choose how you'd like to receive notifications
                    </DialogDescription>
                </DialogHeader>

                <PreferencesForm onSuccess={onSuccess} />


            </DialogContent>
        </Dialog>
    );
}