import { useMutation } from "@tanstack/react-query";
import { toast } from "sonner";
import { supabaseClient } from "../../../lib/supabase";

export const syncDiaryStates = async () => {
	const { data, error } = await supabaseClient.functions.invoke(
		"public-get-diaries",
		{
			body: {},
		},
	);
	if (error) {
		throw error;
	}
	return data;
};

export const useSyncDiaryStates = () => {
	return useMutation({
		mutationFn: syncDiaryStates,
		onSuccess: () => {
			toast.success("Diary states synced");
		},
		onError: () => {
			toast.error("Error syncing diary states");
		},
	});
};
