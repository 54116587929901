import { createFileRoute, Outlet } from "@tanstack/react-router";
import { diaryByIdQueryOptions } from "../../../../features/diary/api/getDiaryById";

const DashboardDiaryIdPage = () => {
	return <Outlet />;
};

export const Route = createFileRoute("/_app/dashboard/diary/$diaryId")({
	component: DashboardDiaryIdPage,
	loader: ({ context, params }) => {
		context?.queryClient.ensureQueryData(
			diaryByIdQueryOptions(params.diaryId),
		);
	},
});
