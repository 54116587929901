import { AlertTriangle } from 'lucide-react'
import { Alert, AlertDescription } from '@/components/ui/alert'
import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useState } from 'react'
import { colors } from '../../../assets/colors'
import { useAuth } from '../../../utils/auth'

interface NotificationsDisabledAlertProps {
    isVisible?: boolean
}

const NotificationsDisabledAlert = ({ isVisible = true }: NotificationsDisabledAlertProps) => {
    const [show, setShow] = useState(isVisible)
    const auth = useAuth()

    useEffect(() => {
        setShow(isVisible)
    }, [isVisible])

    const alertMessage = auth.roles.isClinician
        ? "You won't receive a weekly summary of your patients' progress if both email and SMS notifications are disabled."
        : "You won't receive reminders about diary entries or messages from your care team if both email and SMS notifications are disabled."

    return (
        <AnimatePresence mode="sync">
            {show && (
                <motion.div
                    layout
                    initial={{ opacity: 0, height: 0, scale: 0.95 }}
                    animate={{ opacity: 1, height: 'auto', scale: 1 }}
                    exit={{ opacity: 0, height: 0, scale: 0.95 }}
                    transition={{
                        duration: 0.2,
                        ease: "easeOut"
                    }}
                >
                    <Alert
                        variant="warning"
                        className="border-amber-200 bg-amber-50 mb-4 text-amber-800"
                    >
                        <AlertTriangle size={20} stroke={colors['amber-600']} />
                        <AlertDescription>
                            {alertMessage}
                        </AlertDescription>
                    </Alert>
                </motion.div>
            )}
        </AnimatePresence>
    )
}

export default NotificationsDisabledAlert