import AuthWrap from "@/features/auth/components/AuthWrap";
import SignupForm from "@/features/auth/components/SignupForm";
import usePasswordSignupMutation from "@/features/auth/hooks/usePasswordSignupMutation";
import type { QuickstartT } from "@/types/database/profiles";
import type { RoleT } from "@/types/zod/auth";
import { createFileRoute } from "@tanstack/react-router";
import SignupTitle from "../../features/auth/components/SignupTitle";
const Signup = () => {
	const search = Route.useSearch();
	console.log(search);

	const signup = usePasswordSignupMutation();
	const signupFunction = (arg) => signup.mutate(arg);

	return (
		<AuthWrap>
			<SignupTitle search={search} />
			<SignupForm signupFunction={signupFunction} search={search} />
		</AuthWrap>
	);
};

export type OrganisationSummary = {
	organisation_id: string;
	organisation_name: string;
};

export type SignupSearch = {
	role?: RoleT;
	email?: string;
	favourite_diary_templates?: string[];
	organisation?: OrganisationSummary;
	quickstart?: QuickstartT;
};

export const validateSignupSearch = (
	search: Record<string, unknown>,
): SignupSearch => {
	return {
		role: (search?.role as RoleT) ?? undefined,
		email: (search?.email as string) ?? undefined,
		favourite_diary_templates:
			(search?.favourite_diary_templates as string[]) ?? undefined,
		organisation: (search?.organisation as OrganisationSummary) ??
			undefined,
		quickstart: (search?.quickstart as QuickstartT) ?? undefined,
	};
};

export const Route = createFileRoute("/_auth/signup")({
	component: Signup,
	validateSearch: validateSignupSearch,
});
