import { useQuery } from '@tanstack/react-query';
import { knockClient } from '../utils/knockClient';
import { useAuth } from '../../../utils/auth';

export const KNOCK_AUTH_QUERY_KEY = ['knock-auth'];

export function useKnockAuth() {
    const { id: userId } = useAuth();

    return useQuery({
        queryKey: KNOCK_AUTH_QUERY_KEY,
        queryFn: async () => {
            await knockClient.authenticate(userId);
            return true;
        },
        enabled: !!userId,
        // Prevent unnecessary refetches since auth state rarely changes
        staleTime: Infinity,
        // Keep the auth state even when window is unfocused
        refetchOnWindowFocus: false,
    });
} 