import { supabaseClient } from "@/lib/supabase";
import { useAuth } from "@/utils/auth";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { toast } from "sonner";

const deleteUser = async () => {
	const { data, error } = await supabaseClient.functions.invoke(
		"public-delete-user",
	);
	if (error) throw error;
	return data;
};
export const useDeleteUser = () => {
	const navigate = useNavigate({ from: "/onboarding/organisation" });
	const auth = useAuth();
	return useMutation({
		mutationFn: () => deleteUser(),
		onSuccess: () => {
			toast.info("User delete");
			auth.logout();
			navigate({ to: "/", search: {}, params: {} });
			window.location.reload();
			window.location.reload();
		},
		onError: (error) => {
			toast.error(error.message);
		},
	});
};
