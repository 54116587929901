import type { RoleKeyT, UsersOrganisationsRolesViewT } from '@/types/database/profiles';

export type RolesT = {
	usersOrganisationsRoles: UsersOrganisationsRolesViewT[];
	roles: RoleKeyT[];
	hasRole: boolean;
	isPatient: boolean;
	isAdmin: boolean;
	isClinician: boolean;
	isSecretary: boolean;
	isPatientOnly: boolean;
	isClinicianOnly: boolean;
	isSecretaryOnly: boolean;
	updateRoles: () => void;
	setRoles: (roles: UsersOrganisationsRolesViewT[]) => void;
	addRole: (role: RoleKeyT) => void;
};

export const roles: RolesT = {
	usersOrganisationsRoles: [],
	roles: [],
	hasRole: false,
	isPatient: false,
	isAdmin: false,
	isClinician: false,
	isSecretary: false,
	isPatientOnly: false,
	isClinicianOnly: false,
	isSecretaryOnly: false,
	updateRoles: () => {
		roles.isPatient = roles.usersOrganisationsRoles.some((r) => r.role?.toLowerCase() === 'patient');
		roles.isClinician = roles.usersOrganisationsRoles.some((r) => r.role?.toLowerCase() === 'clinician');
		roles.isSecretary = roles.usersOrganisationsRoles.some((r) => r.role?.toLowerCase() === 'secretary');
		roles.isAdmin = roles.usersOrganisationsRoles.some((r) => r.role?.toLowerCase() === 'admin');
		roles.roles = roles.usersOrganisationsRoles.map((r) => r.role?.toLowerCase() as RoleKeyT);
		roles.isPatientOnly = roles.isPatient && !roles.isClinician && !roles.isSecretary;
		roles.isClinicianOnly = roles.isClinician && !roles.isPatient && !roles.isSecretary;
		roles.isSecretaryOnly = roles.isSecretary && !roles.isPatient && !roles.isClinician;
	},
	setRoles: (data: UsersOrganisationsRolesViewT[]) => {
		roles.usersOrganisationsRoles = data ?? [];
		roles.hasRole = data?.length !== 0;
		roles.updateRoles();
	},
	addRole: (role: RoleKeyT) => {
		roles.roles.push(role);
		roles.updateRoles();
	},
};
