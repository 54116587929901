import { useAuth } from "@/utils/auth";
import { Outlet, createFileRoute } from "@tanstack/react-router";
import UnauthorizedPage from "../../components/pages/UnauthorizedPage";

export const AdminPage = () => {
	const auth = useAuth();

	if (auth?.roles?.isAdmin) {
		return <Outlet />;
	}
	return <UnauthorizedPage message="Admin Only Page" />;
};

export const Route = createFileRoute("/_app/admin")({
	component: AdminPage,
});
