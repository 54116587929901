import Section from "@/features/website/components/Section";
import PartnersReel from "@/features/website/landing/PartnersReel";
// import LaptopAndPhone from '@/features/website/landing/assets/laptop-and-phone-patient-watch.png';
import DesktopMobile from "@/features/website/landing/assets/desktop-mobile-patient-watch.png";
import { useAuth } from "@/utils/auth";
import { useNavigate } from "@tanstack/react-router";
import { ArrowBigDown, ArrowBigUp } from "lucide-react";

import { landingComponents as lc } from ".";
import GetInTouch from "../components/GetInTouch";
import { ComplianceReel } from "./ComplianceReel";
import { WordAnimation } from "./components/WordAnimation";
import { generalFAQs } from "./content/generalFaqs";

// TODO - Testimonial
const Landing = () => {
	const auth = useAuth();
	const navigate = useNavigate();
	return (
		<div>
			<Section className="pt-16 pb-8">
				<lc.Hero
					// title={<PowerToThePatients />}
					title={
						<span className="font-light dark:text-slate-100 transition-all duration-1000">
							Remote Follow-Ups to
							<br />
							Save Time for <WordAnimation />
						</span>
					}
					description="Make smarter treatment decisions, gather audit data and enjoy happier patients with real-time remote monitoring."
					primaryText={auth.id ? "Sign In" : "Sign Up / Sign In"}
					primaryOnClick={() =>
						auth.id
							? navigate({ to: "/profile" })
							: navigate({ to: "/authenticate", search: {} })
					}
					// secondaryText="My Profile"
					// secondaryOnClick={() => navigate({ to: '/profile' })}
					image={DesktopMobile}
					disclaimer={
						<div className="flex flex-row gap-2 mt-8 p-2 text-slate-800 lg:text-md dark:text-slate-200">
							<div className="flex flex-row items-center gap-1">
								<ArrowBigUp />
								Improve Care
							</div>
							<div className="flex flex-row items-center gap-1">
								<ArrowBigDown />
								Save Time
							</div>
						</div>
					}
					hideSecondary={!auth.isAuthenticated}
				/>
			</Section>

			<lc.Problem />

			{/* <Section color="indigo">
                <div className="flex flex-col gap-y-4 mx-auto px-4 font-medium text-center text-lg text-slate-800 lg:text-xl">
                    <p>
                        <i>"Patient Watch has saved our department countless unnecessary appointments."</i>
                    </p>
                </div>
            </Section> */}

			<lc.DiaryDemo />
			<lc.Actions />
			<lc.Report />

			<lc.Why />

			<Section
				className="mt-10 mb-20"
				title={
					<span className="font-semibold text-2xl text-slate-800 dark:text-slate-200">
						Proud to Work with
					</span>
				}
			>
				<PartnersReel />
				<span className="mx-auto font-semibold text-slate-800 text-xl dark:text-slate-200">
					and be Acredited by
				</span>
				<ComplianceReel />
			</Section>

			<Section>
				<GetInTouch />
			</Section>

			<Section>
				<lc.FaqBlock FAQs={generalFAQs} />
			</Section>
		</div>
	);
};

export default Landing;
