import { ui } from '@/components/ui';
import { cn } from '@/lib/utils';
import PreferencesDialog from './PreferencesDialog';
import PreferencesPreview from './PreferencesPreview';
import { usePreferencesQuery } from '../api/preferencesQuery';

const PreferencesCard = () => {
	const { data: preferences = {} } = usePreferencesQuery();
	const noPreferences = Object.keys(preferences).length === 0;

	return (
		<ui.Card className="mb-4">
			<ui.CardHeader className="flex flex-row justify-between items-center pb-0 w-full">
				<ui.CardTitle className={cn('text-xl', noPreferences && 'text-amber-600')}>
					Notification Preferences
					{noPreferences && 'Missing'}
				</ui.CardTitle>
				<PreferencesDialog trigger={<ui.Button variant='link' className='my-0 px-2 py-0 w-fit h-fit'>Edit</ui.Button>} />
			</ui.CardHeader>
			<ui.CardContent className="pt-4">
				<PreferencesPreview />
			</ui.CardContent>
		</ui.Card>
	);
};

export default PreferencesCard;
