import { useQuery } from '@tanstack/react-query';
import { knockClient } from '../utils/knockClient';
import { useKnockAuth } from './useKnockAuth';

export const preferencesQueryKey = { queryKey: ['knock-preferences'] };

export function usePreferencesQuery() {
    // Wait for auth to complete before fetching preferences
    const { isSuccess: isAuthenticated } = useKnockAuth();

    return useQuery({
        ...preferencesQueryKey,
        queryFn: () => knockClient.user.getPreferences(),
        // Only fetch preferences after auth is successful
        enabled: isAuthenticated,
    });
}

// Optional helper hook for checking if all notifications are disabled
export function useAreAllNotificationsDisabled() {
    const { data: preferences } = usePreferencesQuery();
    return !preferences?.channel_types?.email && !preferences?.channel_types?.sms;
}