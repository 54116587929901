import { ui } from '@/components/ui';
import { Route as WatchRoute } from '@/routes/_app/new-diary/patient/select';
import { useAuth } from '@/utils/auth';
import { Link, useNavigate } from '@tanstack/react-router';
import { Lock } from 'lucide-react';
import { toast } from 'sonner';

const NewDiaryButton = () => {
	const auth = useAuth();
	const navigate = useNavigate();
	if (auth.isSuperUser) {
		<ui.Button asChild variant="ghost" className="flex mx-auto my-0 w-fit cursor-not-allowed">
			Create new diary
		</ui.Button>;
	}
	return (
		<ui.Button
			onClick={() =>
				toast('Patients cannot create diaries yet.', {
					description: 'Interested in this feature?',
					action: {
						label: 'Contact Us',
						onClick: () => navigate({ to: '/contact' }),
					},
				})
			}
			disabled={true}
			asChild
			variant="ghost"
			className="flex mx-auto my-0 w-fit"
		>
			<Link disabled={true} to={WatchRoute.to} params={{ patientId: auth.id }}>
				Create new diary <Lock />
			</Link>
		</ui.Button>
	);
};

export default NewDiaryButton;
