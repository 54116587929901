import { Progress } from '@/components/ui/progress';
import { cn } from '@/lib/utils';

import useProfileProgress from '../hooks/useProfileProgress';

interface Props {
	userId: string;
}

const ProfileProgress = ({ userId }: Props) => {
	const { progress, incomplete } = useProfileProgress(userId);
	if (!incomplete) {
		return <></>;
	}
	if (!progress) {
		return <></>;
	}
	return (
		<div className={cn('flex w-full flex-col gap-y-2 px-4 pb-4', progress === 100 && 'hidden')}>
			<h4 className="font-semibold text-lg text-slate-700 dark:text-slate-200">
				Profile Completion {String(progress)}%
			</h4>
			<Progress value={progress} />
		</div>
	);
};

export default ProfileProgress;
