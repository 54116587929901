import { hooks as h } from '@/hooks';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

import { selectProfileByIdQueryOptions } from '../api/selectProfile';
import useProfileAttributes from './useProfileAttributes';

const useProfileProgress = (userId: string) => {
	const { data: profile } = useSuspenseQuery(selectProfileByIdQueryOptions(userId));

	const [progress, setProgress] = h.useLocalStorage('profile_progress', null);
	const [incomplete, setIncomplete] = h.useLocalStorage('profile_incomplete', undefined);
	const profileAttributes = useProfileAttributes(profile);
	const recalculateProfileProgress = () => {
		const total = Object.values(profileAttributes);
		const incomplete = Object.entries(profileAttributes).some(([, attribute]) => attribute === true);
		const numberIncomplete = Object.entries(profileAttributes).filter(([, attribute]) => attribute === true).length;

		setIncomplete(incomplete || !profile?.organisations?.length);

		if (total) {
			const newProgress = Math.round(((total.length - numberIncomplete) / total.length) * 100);
			setProgress(newProgress);
		}
	};

	useEffect(() => {
		recalculateProfileProgress();
	}, [profile]);

	return { progress, incomplete, recalculateProfileProgress };
};

export default useProfileProgress;
