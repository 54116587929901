import Title from "@/components/common/Title";
import { ui } from "@/components/ui";
import { useGetSession } from "@/features/admin/api/getSession";
import AdminPageCardLink from "@/features/admin/components/AdminPageCardLink";
import ImpersonateUserDialog from "@/features/admin/components/ImpersonateUserDialog";
import UpdateAudiencesCard from "@/features/admin/components/UpdateAudiencesCard";
import UsageStatistics from "@/features/admin/components/UsageStatistics";
import { useNav } from "@/utils/nav";
import { createFileRoute } from "@tanstack/react-router";
import { useSyncDiaryStates } from "../../../features/admin/api/syncDiaryStates";

const AdminPage = () => {
	useNav([{ label: "Admin", route: { to: "/admin" } }]);
	const { refetch: getSession } = useGetSession();
	const { mutate: syncDiaryStates } = useSyncDiaryStates();

	return (
		<section className="flex flex-col gap-4 p-4">
			<Title
				words="Admin"
				description="Here you can view what is happening on with Patient Watch and managing users and organisations."
			/>
			<UsageStatistics />

			{/* Diary Management Section */}
			<ui.Card className="border-sky-300 dark:border-sky-700 bg-sky-100 dark:bg-sky-900">
				<ui.CardHeader>
					<ui.CardTitle>Diary Management</ui.CardTitle>
					<ui.CardDescription>
						Manage and view diaries, events, and trackers.
					</ui.CardDescription>
				</ui.CardHeader>
				<ui.CardContent>
					<div className="flex md:flex-row flex-col gap-4">
						<AdminPageCardLink
							title="Diaries"
							description="See all Patient Watch diaries."
							to={{ to: "/admin/diaries" }}
						/>
						<AdminPageCardLink
							title="Events"
							description="Show the events on Patient Watch diaries."
							to={{ to: "/admin/events" }}
						/>
						<AdminPageCardLink
							title="Trackers"
							description="See what trackers are in progress at Patient Watch."
							to={{ to: "/admin/trackers" }}
						/>
					</div>
				</ui.CardContent>
			</ui.Card>

			{/* User and Organisation Management Section */}
			<ui.Card className="border-pink-300 dark:border-pink-700 bg-pink-100 dark:bg-pink-900">
				<ui.CardHeader>
					<ui.CardTitle>User and Organisation Management</ui.CardTitle>
					<ui.CardDescription>
						Manage users and organisations.
					</ui.CardDescription>
				</ui.CardHeader>
				<ui.CardContent>
					<div className="flex md:flex-row flex-col gap-4">
						<AdminPageCardLink
							title="Invited Users"
							description="See all users who have been invited to join Patient Watch."
							to={{ to: "/admin/invited-users" }}
						/>
						<AdminPageCardLink
							title="Users"
							description="Filter users by organisation and see their profiles."
							to={{ to: "/admin/users" }}
						/>
						<AdminPageCardLink
							title="Users Statistics"
							description="See how many diaries each user has created."
							to={{ to: "/admin/organisations-statistics" }}
						/>
						<AdminPageCardLink
							title="Update Email"
							description="Update user email addresses."
							to={{ to: "/admin/update-email" }}
						/>
					</div>
				</ui.CardContent>
			</ui.Card>

			{/* User and Organisation Statistics Section */}
			<ui.Card className="bg-teal-100 dark:bg-teal-900 border-teal-300 dark:border-teal-700">
				<ui.CardHeader>
					<ui.CardTitle>Statistics</ui.CardTitle>
					<ui.CardDescription>
						View statistics for clinicians, users, and organisations.
					</ui.CardDescription>
				</ui.CardHeader>
				<ui.CardContent>
					<div className="flex md:flex-row flex-col gap-4">
						<AdminPageCardLink
							title="Clinicians Statistics"
							description="A history of the clinicians roundups."
							to={{ to: "/admin/clinicians/statistics" }}
						/>
						<AdminPageCardLink
							title="Clinicians Leaderboard"
							description="The best performing clinicians of the week."
							to={{ to: "/admin/clinicians/leaderboard" }}
						/>
					</div>
				</ui.CardContent>
			</ui.Card>

			{/* User and Organisation Statistics Section */}
			<ui.Card className="border-violet-300 dark:border-violet-700 bg-violet-100 dark:bg-violet-900">
				<ui.CardHeader>
					<ui.CardTitle>Audiences</ui.CardTitle>
					<ui.CardDescription>
						Manage the audiences for Resend.
					</ui.CardDescription>
				</ui.CardHeader>
				<ui.CardContent>
					<div className="flex md:flex-row flex-col gap-4">
						<UpdateAudiencesCard />
					</div>
				</ui.CardContent>
			</ui.Card>

			{/* User and Organisation Management Section */}
			<ui.Card className="border-purple-300 dark:border-purple-700 bg-purple-100 dark:bg-purple-900">
				<ui.CardHeader>
					<ui.CardTitle>User and Organisation Management</ui.CardTitle>
					<ui.CardDescription>
						Manage users and organisations.
					</ui.CardDescription>
				</ui.CardHeader>
				<ui.CardContent>
					<div className="flex md:flex-row flex-col gap-4">
						<AdminPageCardLink
							title="Add Organisation"
							description="Add a new organisation with a code to join."
							to={{ to: "/admin/add-organisation" }}
							variant="default"
						/>
						<AdminPageCardLink
							title="Organisations Statistics"
							description="See how many diaries and users are in each organisation."
							to={{ to: "/admin/organisations-statistics" }}
						/>
					</div>
				</ui.CardContent>
			</ui.Card>

			{/* Admin Tools Section */}
			<ui.Card className="border-yellow-300 dark:border-yellow-700 bg-yellow-100 dark:bg-yellow-900">
				<ui.CardHeader>
					<ui.CardTitle>Admin Tools</ui.CardTitle>
					<ui.CardDescription>
						Advanced tools for administrators.
					</ui.CardDescription>
				</ui.CardHeader>
				<ui.CardContent>
					<div className="flex md:flex-row flex-col gap-4">
						<AdminPageCardLink
							title="Get Session"
							description="Get the session of the current admin user"
							button={
								<ui.Button size="md" onClick={() => getSession()}>
									Get Session
								</ui.Button>
							}
						/>
						<AdminPageCardLink
							title="Impersonate User"
							description="Login as a user to see what they see"
							button={<ImpersonateUserDialog />}
						/>
						<AdminPageCardLink
							title="Sync Diary States"
							description=""
							button={
								<ui.Button size="md" onClick={() => syncDiaryStates()}>
									Sync Diary States
								</ui.Button>
							}
						/>
					</div>
				</ui.CardContent>
			</ui.Card>
		</section>
	);
};

export const Route = createFileRoute("/_app/admin/")({
	component: AdminPage,
});
