import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import dayjs from "dayjs";
import { toast } from "sonner";
import { supabaseClient } from "../../../lib/supabase";

interface SignupProps {
	email: string;
	password: string;
	options: any;
}

const signup = async (args: SignupProps) => {
	const { data, error } = await supabaseClient.auth.signUp(args);
	if (error) throw error;
	return data;
};

import type { SignupSchemaType } from "../types/signupSchema";
import { createRedirectUrl } from "../utils/createRedirectUrl";

const usePasswordSignupMutation = () => {
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	return useMutation({
		mutationKey: ["signup"],
		mutationFn: async (formData: SignupSchemaType) =>
			signup({
				email: formData.email,
				password: formData.password,
				options: {
					data: formData.metadata,
					emailRedirectTo: createRedirectUrl("/onboarding/name"),
					redirectTo: createRedirectUrl("/onboarding/name"),
				},
			}),
		onError: (error) => {
			toast.error(error.message);
		},
		onSuccess: (mutation) => {
			toast.success("Sign-up successful");
			navigate({
				to: "/verify",
				search: { email: mutation?.user?.email as string },
			});
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: ["profile"] });
			queryClient.invalidateQueries({ queryKey: ["user"] });
		},
	});
};

export default usePasswordSignupMutation;

export const useRequestTransfer = () => {
	return useMutation({
		mutationKey: ["signup"],
		mutationFn: async (formData: SignupSchemaType) =>
			signup({
				email: formData.email,
				password: formData.password,
				options: { data: formData.metadata },
			}),
		onError: (error) => {
			toast.error(error.message);
		},
		onSuccess: (data) => {
			toast.success(
				"Transfer requested - please check your email to ensure it went through.",
			);
			supabaseClient.functions.invoke("public-email", {
				body: {
					to_email: "info@patient-watch.com",
					cc_email: data.user?.email,
					from_email: "notification@reminders.footwatch.co.uk",
					subject: "Transfer to New Patient Watch",
					body:
						`Transfer request for email ${data.user?.email} at ${dayjs().toString()}`,
				},
			});
		},
	});
};
