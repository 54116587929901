import QrInviteForm from "@/features/dub/components/QrInviteForm";
import type { OrganisationSummary, SignupSearch } from "@/routes/_auth/signup";
import type { QuickstartT } from "@/types/database/profiles";
import type { RoleT } from "@/types/zod/auth";
import { createFileRoute } from "@tanstack/react-router";

const OrganisationQuickstartQrCodePage = () => {
	const search = Route.useSearch();

	return (
		<section className="md-width">
			<QrInviteForm
				search={search}
				showFields={{ organisations: true, roles: true }}
			/>
		</section>
	);
};

export const Route = createFileRoute("/_app/invite/qr-code")({
	component: OrganisationQuickstartQrCodePage,
	validateSearch: (search: Record<string, unknown>): SignupSearch => {
		return {
			role: (search?.role as RoleT) ?? undefined,
			email: (search?.email as string) ?? undefined,
			favourite_diary_templates:
				(search?.favourite_diary_templates as string[]) ?? undefined,
			organisation: (search?.organisation as OrganisationSummary) ??
				undefined,
			quickstart: (search?.quickstart as QuickstartT) ?? undefined,
		};
	},
});
