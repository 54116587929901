import { supabaseClient } from "@/lib/supabase";
import type { DiariesCountT } from "@/types/database/diaries";
import { type Auth, useAuth } from "@/utils/auth";
import { queryOptions, useQuery } from "@tanstack/react-query";
import type { DashboardSearchT } from "../../../routes/_app/dashboard";

function countBy<T>(
	array: T[],
	iteratee: (item: T) => string | number,
): Record<string, number> {
	return array.reduce(
		(acc, item) => {
			const key = iteratee(item);
			acc[key] = (acc[key] || 0) + 1;
			return acc;
		},
		{} as Record<string, number>,
	);
}

export const prepDiariesData = (settings: DashboardSearchT, auth?: Auth) => {
	if (!settings?.tab) throw new Error("Tab is required");

	if (!auth) throw new Error("Not logged in");

	return {
		...settings,
		diary_access: settings?.diary_access ?? undefined,
		user_id: settings?.diaries === "mine" ? auth.id : undefined,
	};
};
export async function selectCountDashboardDiaries(
	settings: DashboardSearchT,
	auth: Auth,
): Promise<DiariesCountT> {
	let query = supabaseClient.from("diaries_view").select("*");

	const { user_id, diary_access, organisations } = prepDiariesData(
		settings,
		auth,
	);

	if (user_id) {
		query = query.contains("direct_access", [user_id]);
	}

	if (diary_access && diary_access?.length > 0) {
		query = query.overlaps("direct_access", diary_access);
	}

	if (organisations && organisations?.length > 0) {
		query = query.in("organisation_id", organisations);
	}

	const { data: diaries, error } = await query;

	if (error) throw error;

	const tabCounts = countBy(diaries, (diary) => diary.tab ?? "unknown");

	return {
		active: tabCounts.active ?? 0,
		pending: tabCounts.pending ?? 0,
		inactive: tabCounts.inactive ?? 0,
	};
}

export const createCountDashboardDiariesQueryKey = (
	search?: DashboardSearchT,
) => ["CountDashboardDiaries", "diaries", { ...search }];

export const countDashboardDiariesQueryOptions = (
	settings: DashboardSearchT,
	auth: Auth,
) => {
	return queryOptions({
		queryKey: createCountDashboardDiariesQueryKey(settings),
		queryFn: () => selectCountDashboardDiaries(settings, auth),
	});
};
export const useCountDashboardDiariesQuery = (settings: DashboardSearchT) => {
	const auth = useAuth();
	return useQuery(countDashboardDiariesQueryOptions(settings, auth));
};
