import { usePreferencesQuery } from '../api/preferencesQuery';
import NotificationsDisabledAlert from './NotificationsDisabledAlert';

const PreferencesPreview = () => {
    const { data: preferences } = usePreferencesQuery();
    const areAllDisabled = !preferences?.channel_types?.email && !preferences?.channel_types?.sms;


    if (!preferences || Object.keys(preferences).length === 0) {
        return <p>No preferences set.</p>;
    }

    return (
        <div>
            <NotificationsDisabledAlert isVisible={areAllDisabled} />
            <ul className='space-y-2 p-4 pt-0 text-md list-disc list-inside'>
                <li>Email: {preferences.channel_types?.email ? 'Enabled' : 'Disabled'}</li>
                <li>SMS: {preferences.channel_types?.sms ? 'Enabled' : 'Disabled'}</li>
            </ul>
        </div>
    );
};

export default PreferencesPreview; 