import { supabaseClient } from "@/lib/supabase";
import { queryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";

export async function selectProfileByEmail(email: string) {
	const { data, error } = await supabaseClient
		.from("profiles")
		.select("email")
		.ilike("email", `%${email}%`);
	if (error) throw error;
	return data;
}

export const selectProfileByEmailQueryOptions = (email: string) =>
	queryOptions({
		queryKey: ["profiles", email],
		queryFn: () => selectProfileByEmail(email),
		enabled: !!email,
	});

export function useSelectProfileByEmail(email: string) {
	const options = selectProfileByEmailQueryOptions(email);
	return useQuery(options);
}
