import { create } from "zustand";
import { Copy as CopyIcon } from "lucide-react";
import { toast } from "sonner";
import { cn } from "@/lib/utils";

interface CopyStore {
  copiedId: string | null;
  setCopiedId: (id: string | null) => void;
}

export const useCopyStore = create<CopyStore>((set) => ({
  copiedId: null,
  setCopiedId: (id) => {
    set({ copiedId: id });
    // Reset after animation
    setTimeout(() => set({ copiedId: null }), 300);
  },
}));

interface CopyButtonProps {
  value: string;
  id: string;
  disabled?: boolean;
  className?: string;
}

export function CopyButton(
  { value, id, disabled = false, className }: CopyButtonProps,
) {
  const setCopiedId = useCopyStore((state) => state.setCopiedId);

  const handleCopy = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    navigator.clipboard.writeText(value);
    setCopiedId(id);
    toast.success("Copied to clipboard");
  };

  return (
    <span
      tabIndex={-1}
      onClick={(e) => handleCopy(e)}
      className={cn(
        "p-1 h-8 w-8 text-slate-600 hover:text-slate-700 rounded-md cursor-pointer",
        disabled && "cursor-not-allowed pointer-events-none",
        className,
      )}
    >
      <CopyIcon size={18} />
    </span>
  );
}

interface CopyableTextProps {
  id: string;
  children: React.ReactNode;
  className?: string;
}

export function CopyableText({ id, children, className }: CopyableTextProps) {
  const copiedId = useCopyStore((state) => state.copiedId);
  const isCurrentlyCopied = copiedId === id;

  return (
    <span className={cn(isCurrentlyCopied && "animate-wiggle", className)}>
      {children}
    </span>
  );
}

interface CopyProps {
  id: string;
  value: string;
  children: React.ReactNode;
  className?: string;
}

export function Copy({ id, value, children, className }: CopyProps) {
  return (
    <div
      className={cn("flex gap-2 items-center w-fit group relative", className)}
    >
      <CopyableText id={id}>{children}</CopyableText>
      <CopyButton
        className="right-0 z-50 absolute opacity-0 group-hover:opacity-100 transition-opacity translate-x-10 cursor-pointer"
        id={id}
        value={value}
      />
    </div>
  );
}
