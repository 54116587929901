import CardGrid from "@/components/common/CardGrid";
import { dashboardDiaryQueryOptions } from "@/features/dashboard/api/useSelectDashboardDiaries";
import DiaryOverviewCard from "@/features/diaries/components/DiaryOverviewCard";
import type { StatefulDiaryT } from "@/types/database/diaries";
import { createFileRoute } from "@tanstack/react-router";
import Title from "../../../components/common/Title";

const DiaryTemplatesPage = () => {
	const { diaries } = Route.useLoaderData();

	const renderDiary = (d: StatefulDiaryT) => <DiaryOverviewCard diary={d} />;

	return (
		<section className="mt-8">
			<Title words={`${diaries.length} Diaries`} />
			<CardGrid items={diaries} renderItem={renderDiary} />
		</section>
	);
};

export const Route = createFileRoute("/_app/admin/diaries")({
	component: DiaryTemplatesPage,
	loader: async ({ context }) => {
		const diaries = (await context?.queryClient.ensureQueryData(
			dashboardDiaryQueryOptions(),
		)) as StatefulDiaryT[];
		return { diaries };
	},
});
