import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";

const words = ["Doctors", "Patients", "Hospitals"];

function useWordCycle(words: string[], interval: number) {
	const [index, setIndex] = useState(0);
	const [isInitial, setIsInitial] = useState(true);

	useEffect(() => {
		if (isInitial) {
			setIndex(Math.floor(Math.random() * words.length));
			setIsInitial(false);
			return;
		}

		const timer = setInterval(() => {
			setIndex((current) => (current + 1) % words.length);
		}, interval);
		return () => clearInterval(timer);
	}, [words, interval, isInitial]);

	return words[index];
}

export function WordAnimation() {
	const word = useWordCycle(words, 3000);

	// Calculate the width based on the longest word

	return (
		<AnimatePresence mode="wait">
			<motion.div
				key={word}
				className="inline-block width-fit transition-all duration-1000"
			>
				{word?.split("").map((char, index) => (
					<motion.span
						key={`${word}-${char}-${index.toString()}`}
						initial={{ y: 20, opacity: 0 }}
						animate={{ y: 0, opacity: 1 }}
						exit={{ y: -20, opacity: 0 }}
						transition={{
							duration: 0.4,
							delay: index * 0.03,
							ease: "easeInOut",
						}}
						style={{ display: "inline-block", whiteSpace: "pre" }}
					>
						{char}
					</motion.span>
				))}
			</motion.div>
		</AnimatePresence>
	);
}
