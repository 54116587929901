import Title from "@/components/common/Title";
import type { SignupSearch } from "@/routes/_auth/signup";
import { CatchBoundary } from "@tanstack/react-router";
import { capitaliseSentence } from "../../../services/capitaliseSentence";

interface TitleContent {
	title: string;
	description?: string;
}

const getTitleContent = (
	search: SignupSearch,
): TitleContent => {
	const organisation = search?.organisation;
	if (!organisation) {
		return { title: "Sign Up" };
	}
	try {
		if (search.quickstart) {
			return {
				title: `Sign up (${search.quickstart.quickstart_name})`,
				description: search.quickstart.quickstart_description ?? "",
			};
		}

		const roleText = search.role
			? ` as ${capitaliseSentence(search.role)}`
			: "";
		const orgText = organisation?.organisation_name
			? ` for ${organisation.organisation_name}`
			: "";

		return {
			title: `Sign up${roleText}${orgText}`,
			description: "",
		};
	} catch (e) {
		return { title: "Sign Up" };
	}
};

const SignupTitle = ({ search }: { search: SignupSearch }) => {
	const titleContent = getTitleContent(search);

	return (
		<CatchBoundary
			getResetKey={() => "signup-title"}
			errorComponent={<Title words="Sign Up" />}
		>
			<Title
				words={titleContent.title}
				description={titleContent.description}
			/>
		</CatchBoundary>
	);
};

export default SignupTitle;
