import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import { knockClient } from '../utils/knockClient';
import { preferencesQueryKey } from './preferencesQuery';

export const usePreferencesMutation = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (data) => knockClient.user.setPreferences({ channel_types: data }),
		onSuccess: (data) => {
			// Invalidate and refetch preferences
			queryClient.invalidateQueries(preferencesQueryKey);
			toast.success('Notification preferences saved');
			return data;
		},
		onError: () => {
			toast.error('Failed to save preferences');
		},
	});
};
