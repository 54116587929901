import { formComponents as fc } from "@/components/form";
import useOrganisationOptions from "@/components/hooks/useOrganisationOptions";

export interface OrganisationOption {
	organisation_id: string;
	organisation_name: string;
}

const SelectFromMyOrganisations = () => {
	const { organisationObjOptions } = useOrganisationOptions();
	return (
		<fc.Select
			name="organisation"
			label="Organisation"
			options={organisationObjOptions}
		/>
	);
};

export default SelectFromMyOrganisations;
